import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				AIMS CONSULTING - Digitalisez votre activité, optimisez votre performance.
			</title>
			<meta name={"description"} content={"Nous sommes spécialisés dans le développement de logiciels et d'applications d'entreprise. Notre mission est de digitaliser les activités de nos clients, tout en améliorant l'efficacité et la performance de leurs équipes."} />
			<meta property={"og:title"} content={"AIMS CONSULTING - Digitalisez votre activité, optimisez votre performance. "} />
			<meta property={"og:description"} content={"Nous sommes spécialisés dans le développement de logiciels et d'applications d'entreprise. Notre mission est de digitaliser les activités de nos clients, tout en améliorant l'efficacité et la performance de leurs équipes. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/OG-image.png?v=2021-09-22T11:33:13.239Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/favicon%2032x32.png?v=2021-09-22T11:33:41.498Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/270x270.png?v=2021-09-22T11:33:55.934Z"} />
		</Helmet>
		<Section padding="38px 0 38px 0" quarkly-title="Header">
			<Override slot="SectionContent" flex-direction="row" display="flex" />
			<Image src="logo.png" display="block" height="50px" />
			
		</Section>
		<Section background="--color-darkL1" padding="0px 0 0px 0" quarkly-title="HeroBlock" md-flex-wrap="wrap">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				padding="80px 0px 40px 0px"
				md-padding="40px 0px 40px 0px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				lg-padding="80px 32px 40px 0px"
				md-width="100%"
			>
				<Box
					lg-width="100%"
					display="flex"
					flex-direction="column"
					width="70%"
					justify-content="space-between"
					flex="1 1 0%"
				>
					<Text font="--headline3" color="--light" sm-font="400 36px/1.2 &quot;Fira Sans&quot;, sans-serif" margin="0px 0px 0px 0px">
						Augmentez votre rentabilité et optimisez votre performance
						<br />
					</Text>
					<Text margin="32px 0px 0px 0px" font="--lead" color="--light" md-padding="0px 0px 40px 0px">
						Nous sommes spécialisés dans le développement de logiciels et d'applications d'entreprise. Notre mission est de transformer et digitaliser les activités de nos clients pour améliorer l'efficacité et la performance de leurs équipes.
					</Text>
				</Box>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
			>
				<Image
					src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					max-width="100%"
					min-height="600px"
					object-fit="cover"
					lg-min-height="500px"
					sm-min-height="400px"
					srcSet="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="20px 0 60px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 54px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Pourquoi nous choisir?
				</Text>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="50%"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 16px 0px 0px"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-primary"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						1
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline4" lg-text-align="left">
							Compétences Technologiques Avancées
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
							Nous maîtrisons les dernières technologies et les meilleures pratiques du secteur. Cette expertise nous permet de créer des solutions robustes, évolutives et adaptées à vos besoins spécifiques.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="50%"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
					margin="0px 0px 32px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-primary"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						2
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline4" lg-text-align="left">
							Orientation Client
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
							Votre succès est notre priorité. Nous croyons fermement en une approche centrée sur le client, ce qui signifie que nous écoutons attentivement vos besoins, collaborons étroitement avec vous tout au long du processus de développement, et fournissons un support continu. Notre objectif est de créer des solutions qui non seulement répondent à vos attentes, mais les dépassent.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="50%"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
					margin="0px 0px 32px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-primary"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						3
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline4" lg-text-align="left">
							Solutions Personnalisées
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
							Chaque entreprise est unique, c'est pourquoi nous offrons des solutions sur mesure. Que vous ayez besoin d'un logiciel spécifique pour améliorer un aspect de votre activité ou d'une application mobile pour vos équipes sur le terrain, nous avons les compétences et l'expérience pour le réaliser.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="50%"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-primary"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						4
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline4" lg-text-align="left">
							Réactivité et Fiabilité
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
							Nous comprenons l'importance de la réactivité et de la fiabilité dans le monde des affaires. Notre équipe est toujours prête à répondre à vos questions, résoudre vos problèmes et vous fournir les mises à jour nécessaires pour que vos systèmes restent performants.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 56px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="0px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				width="50%"
				align-items="center"
				border-style="solid"
				padding="80px 0px 80px 0px"
				md-padding="60px 0px 60px 0px"
				empty-border-width="1px"
				border-color="#C4C4C4"
				empty-border-style="solid"
				empty-border-color="LightGray"
				border-width="1px 0px 0px 0px"
				empty-min-height="64px"
				display="flex"
				md-width="100%"
			>
				<Image src="logo.png" display="block" height="70px" />
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline4"
					color="--light"
					lg-font="normal 400 20px/1.3 &quot;Fira Sans&quot;, sans-serif"
					sm-font="normal 400 18px/1.4 &quot;Fira Sans&quot;, sans-serif"
					background="--color-primary"
					padding="32px 24px 32px 24px"
				>
					Nos clients témoignent de notre capacité à fournir des solutions efficaces qui améliorent significativement leurs opérations.{" "}
					<br />
					Votre satisfaction est la meilleure mesure de notre succès.
				</Text>
			</Box>
		</Section>
		<Section padding="32px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-height="64px"
				sm-align-items="center"
				sm-justify-content="center"
				empty-border-width="1px"
				background="--color-dark"
				md-width="100%"
				sm-display="flex"
				sm-padding="32px 16px 32px 16px"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-border-color="LightGray"
				padding="64px 32px 257px 0px"
				md-padding="64px 32px 64px 32px"
			>
				<Box display="flex" flex-direction="column" width="80%" lg-width="100%">
					<Text
						color="--light"
						sm-font="400 36px/1.2 &quot;Fira Sans&quot;, sans-serif"
						lg-text-align="center"
						lg-margin="0px 0px 0px 0px"
						margin="0px 0px 0px 32px"
						font="--headline3"
					>
						Témoignages
					</Text>
					<Box background="--color-darkL2" padding="32px 35px 32px 32px" margin="32px 0px 0px 0px">
						<Text color="--light" margin="0px 0px 0px 0px" lg-text-align="center" font="--lead">
							Grâce à AIMS CONSULT, nous avons pu automatiser nos processus de vente, ce qui nous a permis d'augmenter notre efficacité de 30%. Leur équipe a été incroyablement réactive et à l'écoute de nos besoins.
						</Text>
						<Text margin="0px 0px 0px 0px" color="#cdcdcd" font="16px sans-serif">
							Nicolas - JIC
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				empty-border-style="solid"
				background="rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1576558656222-ba66febe3dec?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 45% 0% /cover repeat scroll padding-box"
				lg-padding="64px 0px 0px 32px"
				md-width="100%"
				md-padding="64px 0px 274px 32px"
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				padding="64px 32px 0px 32px"
				empty-min-width="64px"
				empty-border-color="LightGray"
			/>
		</Section>
		<Section background="--color-darkL1" padding="80px 0 80px 0" margin="30px 0 0 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Contacts{"  "}
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Vous avez un projet en tête ou des questions ? Contactez-nous pour discuter de la manière dont nous pouvons vous aider à digitaliser et à améliorer la performance de votre entreprise, avec un service personnalisé et une relation de confiance.
				</Text>
				<Link
					href="mailto:blank?hello@company.com"
					text-align="center"
					color="--light"
					font="--headline4"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					arbi.benali@aims-consult.com
				</Link>
				<Link
					href="tel:1234567899"
					text-align="center"
					color="--light"
					font="--headline4"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					+33 6 50 31 84 06
				</Link>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					8 allée Robert Doisneau, 92100 Boulogne Bilancourt, France
				</Text>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6143a05129a0a1001e6c835a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n* {\n    scroll-behavior: smooth;\n    }"}
			</style>
		</RawHtml>
	</Theme>;
});